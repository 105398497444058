.main-nav-logo {
   padding: 2px;

   img {
      height: 3.5rem;
      max-height: 3.5rem;
   }
}

.main-nav {
   padding: 1rem;

   @include touch {
      text-align: center;
   }

   @include widescreen {
      position: fixed;
      min-width: 10rem;
   }
}