.post {
   border-bottom: 1px solid #666;
   margin-bottom: 3rem;
   padding-bottom: 1rem;
   width: auto;

   .post-image-container {
      text-align: center;
   }
   .post-image{
      max-height: 90vh;
      max-width: 100%;
   }
}