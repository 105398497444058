.social-link {
  display: block;
  width: 100%;
  padding: 1em;
  margin-top: .5em;
  text-align: center;
  background-color: #666;

  :hover {
    background-color: #555;
  }
}